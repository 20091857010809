import { StyleSheet } from "aphrodite";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { fontSize } from "main/javascripts/styles/base/typographyStyle";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import { boxShadow } from "main/javascripts/styles/base/shadowStyle";
import { borderColor } from "main/javascripts/styles/base/colorStyle";

export interface IStyle {
  block: any;
  logoBlock: any;
  logo: any;
  buttonBlock: any;
  button: any;
}

export const HeaderStyle: IStyle = StyleSheet.create({
  block: {
    position: "relative",
    height: "56px",
    padding: `0 ${space.block}`,
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: `1px solid ${borderColor.shadowColor}`,
    boxShadow: boxShadow.interface,
    [moreThanBreakpoint("desktop")]: {
      height: "72px",
    },
  },
  logo: {
    width: "120px",
    marginTop: "4px",
    [moreThanBreakpoint("desktop")]: {
      width: "148px",
    },
  },
  logoBlock: {
    boxSizing: "border-box",
    padding: space.atom,
  },
  buttonBlock: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  button: {
    height: "32px",
    lineHeight: "32px",
    fontSize: fontSize.caption,
    letterSpacing: "0.05em",
  },
});
