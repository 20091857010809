import * as React from "react";
import Link from "next/link";
import { css, StyleSheet } from "aphrodite";
import {
  breakpointValue,
  moreThanBreakpoint,
} from "main/javascripts/styles/base/responsiveStyle";
// import { AButton } from "main/javascripts/components/atoms/AButton";
import logoImg from "main/javascripts/images/logo_tarip.svg";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { fontSize } from "main/javascripts/styles/base/typographyStyle";
import { User } from "main/javascripts/types/user";

export interface IProps {
  currentUser: User.User | null;
}

export interface IActionsProps {
  actions: {
    setActiveBreakpoint: any;
  };
}

export class HeaderTravelvision extends React.Component<
  IProps & IActionsProps
> {
  private mediaQueryData: { [key: string]: MediaQueryList };
  constructor(props: any) {
    super(props);
    this.mediaQueryData = {};
  }

  public componentDidMount(): void {
    breakpointValue.forEach((value: number, key: string) => {
      const query: MediaQueryList = window.matchMedia(
        `(min-width: ${value}px)`
      );
      const breakpointChange: any = (): void => {
        this.dispatchActiveQuery();
      };
      query.addListener(breakpointChange);
      this.mediaQueryData = { [key]: query, ...this.mediaQueryData };
    });
    this.dispatchActiveQuery();
  }

  private dispatchActiveQuery(): void {
    let queryKey = "phone";
    breakpointValue.forEach((_: number, key: string) => {
      const query: MediaQueryList = this.mediaQueryData[key];
      if (query.matches) {
        queryKey = key;
        return;
      }
    });
    this.props.actions.setActiveBreakpoint(queryKey);
  }

  public render(): JSX.Element {
    // const { currentUser } = this.props;
    return (
      <div className={css(HeaderStyle.block)}>
        <div className={css(HeaderStyle.logoBlock)}>
          <Link href="/" passHref>
            <a>
              <img src={logoImg} alt="logo" className={css(HeaderStyle.logo)} />
            </a>
          </Link>
        </div>
        {/* <AButton
          styles={{block: HeaderStyle.buttonBlock, button: HeaderStyle.button}}
          href={currentUser ? "/reservations" : "/users/sign_in"}
        >
          <div>{currentUser ? "マイページ" : "ログイン"}</div>
        </AButton> */}
      </div>
    );
  }
}

export interface IStyle {
  block: any;
  logoBlock: any;
  logo: any;
  buttonBlock: any;
  button: any;
}

export const HeaderStyle: IStyle = StyleSheet.create({
  block: {
    position: "relative",
    height: "50px",
    padding: `0 ${space.block}`,
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 3px 10px rgb(0, 0, 0, 0.08)",
  },
  logo: {
    width: "auto",
    height: "20px",
    marginTop: "2px",
    transform: "translateX(-3%)",
    [moreThanBreakpoint("desktop")]: {
      height: "24px",
    },
  },
  logoBlock: {
    boxSizing: "border-box",
    padding: space.atom,
  },
  buttonBlock: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  button: {
    height: "32px",
    lineHeight: "32px",
    fontSize: fontSize.caption,
    letterSpacing: "0.05em",
  },
});
